import React from 'react'
import {
  FaGithub,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaDiscord,
  FaCalendar,
  FaEthereum,
  FaEthernet,
} from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'

import { Layout } from 'layout'
import { SNSButton, LinkButton } from 'component/Button'

import 'style/index.scss'

const Index = props => {
  return (
    <Layout {...props}>
      <div className="bContainer">
        <div className="bCard">
          {/* Main Image */}
          <div className="img">
            <LinkButton to="/">
              <img
                className="img-circle"
                src="https://res.cloudinary.com/devdash54321/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1559761033/DSC09460.jpg"
                alt="MainImge"
              />
            </LinkButton>
          </div>
          {/* My Name */}
          <div className="name">girthbrooks, no relation</div>
          {/* Title */}
          <div className="title">SexWLO Store Coming Soon...</div>
          {/* SNS Buttons */}
          {/* Title */}
          <div className="title">SpankChain && CryptoTitties Profile</div>
          <div className="sns">
            <SNSButton
              key={'SpankChain_button'}
              to="https://beta.spankchain.com/girthbrooks/profile"
            >
              <FaEthernet size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'CryptoTitties_button'}
              to="https://cryptotitties.com/details/47308419"
            >
              <FaEthereum size="1.5rem" />
            </SNSButton>
          </div>
          {/* Title */}
          <div className="title">Streaming Channels for girthbrooks</div>
          <div className="sns">
            <SNSButton
              key={'SpankChain_button'}
              to="https://beta.spankchain.com/girthbrooks"
            >
              <FaEthereum size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'Twitch_button'}
              to="https://www.twitch.tv/drgirthbrooks"
            >
              <FaTwitch size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'Youtube_button'}
              to="https://www.youtube.com/channel/UCvh-Vs4Cydq9uUX2Yvm1G4g?view_as=subscriber"
            >
              <FaYoutube size="1.5rem" />
            </SNSButton>
          </div>
          {/* Title */}
          <div className="title">Social Channels for girthbrooks</div>
          <div className="sns">
            <SNSButton
              key={'Mail_button'}
              to={'mailto:gb@sexwiththelightson.net'}
            >
              <IoIosMail size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'Github_button'}
              to="https://github.com/drgirthbrooks"
            >
              <FaGithub size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'FaceBook_button'}
              to="https://www.facebook.com/SexWithTheLightsOn.net/"
            >
              <FaFacebook size="1.5rem" />
            </SNSButton>
            <SNSButton
              key={'Instagram_button'}
              to={'https://www.instagram.com/drgirthbrooks/'}
            >
              <FaInstagram size="1.5rem" />
            </SNSButton>
          </div>

          {/* Bottom Button */}
          {/* <div className="menu">
            <div className="menuItem">
              <LinkButton to="/posts">All Post</LinkButton>
            </div>
            <div className="menuItem">
              <LinkButton to="/category">Category</LinkButton>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default Index
